@import "../../../_global.scss";

.loading {
    position: relative;
    height: 100px;
    top: 0; right: 0;
    bottom: 0; left: 0;
}
.loader {
    left: 50%;
    margin-left: -2rem;
    font-size: .7rem;
    border: .3rem solid darken($beige-color, 10%);
    border-left: .3rem solid $brown-color;
    animation: spin 1.1s infinite linear;
}
.loader, .loader:after {
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -2rem;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
