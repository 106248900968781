@import "../../_global.scss";

.dictionary-content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .daily-word {
        width: 100%;
        order: 1;

        .explanation {
            text-align: left;

            * {
                margin: 20px 0;
            }

            .divider {
                width: 100px;
            }
        }
    }

    .explore {
        width: 100%;
        order: 2;

        h2 {
            margin: 10px;
        }

        .search {
            margin: 20px 0;
            background-color: $brown-color;
            color: $white-color;
            font-size: x-large;
            font-weight: bold;
            padding: 0.5rem;
            cursor: pointer;
        }
    }
}

@media only screen and (min-width: 768px) {
    .dictionary-content {

        .daily-word {
            width: 100%;
            order: 2;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .explanation {
                width: 300px;
                margin: 20px;
            }

            .word {
                width: 400px;
            }

            .word-container {
                margin: 0;
            }
        }

        .explore {
            order: 1;
            position: relative;

            .icon-search {
                width: 67px;
                height: 63px;
                position: absolute;
                top: 0;
                right: 0;
                padding: 20px;
                cursor: pointer;
            }
        }
    }
}
