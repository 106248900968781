@import "../../_global.scss";

.word-container {
    text-align: left;
    padding: 1.5rem;
    margin: 1.2rem auto;
    box-sizing: border-box;
    border: 7px solid $brown-color;
}

@media only screen and (min-width: 768px) {
    .word-container {
        max-width: 600px;
    }
}

.word-name {
    font-weight: 900;
    margin-bottom: 2rem;
}

.meaning-container + .meaning-container {
    margin-top: 1.2rem;
}

.meaning-description::first-letter {
    text-transform: capitalize;
}

.meaning-description + .meaning-synonyms, .meaning-description + .meaning-related {
    margin-top: 0.6rem;
}

.meaning-synonyms, .meaning-related {
    font-weight: 900;

    button:first-of-type {
        margin-left: 0;
    }
}

.meaning-type {
    margin-bottom: .3rem;
    font-size: .8rem;
}

.meaning-scientific {
    margin: .3rem 0;
    font-style: italic;
    font-size: .8rem;
}

.meaning-description {
    margin: .3rem 0;
}
