@import "../../../_global.scss";

.keyboard {

    .keyboard-button {
        background-color: $brown-color;
        color: $white-color;
        font-size: x-large;
        font-weight: bold;
        padding: 0.5rem;
        cursor: pointer;
    }

    ul {
        background-color: $brown-color;
        padding: 1rem;
    }

    button, button:hover {
        width: 40px;
        height: 40px;
        font-size: small;
        background-color: $white-color;
        color: $brown-color;
        padding: .7rem;
        margin: .4rem;
        border-radius: 20px;
        cursor: pointer;
        border: none;
        transition: background-color .2s ease;
        text-transform: none;
    }

    button:hover, button:focus {
        background-color: $bronze-color;
    }

    button.active {
        background-color: $bronze-color;
        font-weight: bold;
        color: $beige-color;
    }
}

@media only screen and (min-width: 768px) {

    .keyboard {

        ul {
            background-color: transparent;
            padding: 1rem;
        }

        button, button:hover {
            width: 50px;
            height: 40px;
            font-size: small;
            background-color: $purple-color;
            color: $white-color;
            padding: .7rem;
            margin: .4rem;
            border-radius: 20px;
            cursor: pointer;
            border: none;
            transition: background-color .2s ease;
            text-transform: none;
        }

        button:hover, button:focus {
            background-color: $bronze-color;
        }

        button.active {
            background-color: $bronze-color;
            color: $brown-color;
            font-weight: bold;
        }
    }
}
