@import "../../../_global.scss";

nav {
    z-index: 1;

    .hidden-if-mobile {
        visibility: hidden;
        display: none;
    }

    .hidden-if-desktop {
        visibility: visible;
        display: block;
    }

    .nav-images {
        height: 65px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 15px;

        .logo {
            display: inline-block;

            img {
                width: auto;
                height: 40px;
            }
        }

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 40px;
                height: auto;
            }
        }
    }


    .nav-menu {
        z-index: 2;
        background-color: $white-color;
        height: calc(100% - 65px);
        width: 100%;
        position: absolute;
        top: 65px;

        ul {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        li {
            text-align: center;
            border-bottom: none;
            font-size: x-large;
            padding: 10px;
            font-weight: bold;
        }

        li.space {
            visibility: collapse;
            display: none;
        }
    }
}

@media only screen and (min-width: 768px) {
    nav {
        .hidden-if-mobile {
            visibility: visible;
            display: block;
        }

        .hidden-if-desktop {
            visibility: hidden;
            display: none;
        }

        .nav-images {
            position: absolute;
            top: 35px;
            padding: 0;
            right: calc(50% - 50px);

            .logo {
                img {
                    width: 70px;
                    height: auto;
                }
            }

            .icon {
                display: none;
            }
        }


        .nav-menu {
            height: 160px;
            position: static;

            ul {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
            }

            li {
                text-align: center;
                border-bottom: none;
                font-size: medium;
                padding: 24px;
                border-top: 5px solid $brown-color;
                border-bottom: 5px solid $brown-color;

                .active {
                    text-decoration: underline;
                }
            }

            li.space {
                display: block;
                visibility: visible;
                width: 200px;
                border-top: none;
                border-bottom: none;
            }
        }
    }
}
