@import '_global.scss';
@import 'commons/components/loader/LoaderComponent.scss';
@import 'commons/components/navbar/NavbarComponent.scss';
@import 'commons/components/footer/FooterComponent.scss';
@import 'commons/components/keyboard/KeyboardComponent.scss';
@import 'dictionary/components/WordComponent.scss';
@import 'dictionary/views/LettersView.scss';
@import 'dictionary/views/SearchView.scss';
@import 'dictionary/views/DictionaryView.scss';

* {
  box-sizing: border-box;
  margin: 0;
  letter-spacing: 0.1rem;
}

html, body, #root {
  height: 100%;
}

body {
  height: 100%;
  background-color: $beige-color;
  color: $brown-color;
  margin: 0;
  font-family: 'Berlin', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  background-color: $white-color;
  height: 65px;
}

.top-mountains {
  margin-top: -1px;
  overflow: hidden;
  height: 63px;

  img {
    min-width: 100%;
  }
}

footer, content:after {
  height: 188px;
}

footer {
  overflow: hidden;
}

.content {
  min-height: calc(100% - 315px);
  width: 100%;
  text-align: center;
  padding: 15px;
}

.hidden-if-mobile {
    visibility: hidden;
    display: none;
}

.hidden-if-desktop {
    visibility: visible;
    display: block;
}
@media only screen and (min-width: 768px) {
  nav {
    height: 140px;
  }

  .content {
    min-height: calc(100% - 390px);
    padding: 20px 10%;
  }

    .hidden-if-mobile {
        visibility: visible;
        display: block;
    }

    .hidden-if-desktop {
        visibility: hidden;
        display: none;
    }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
    font-size: 3rem;
    font-weight: 900;
}

h2 {
  font-size: 2rem;
  font-weight: 900;
}

p {
  font-size: 1.2rem;
}

label {
  font-size: .8rem;
}

button, button:hover {
  font-size: .8rem;
  background-color: $brown-color;
  -webkit-tap-highlight-color: $brown-color;
  padding: .7rem;
  margin: .4rem;
  border: none;
  border-radius: 5px;
  color: $beige-color;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  transition: background-color .2s ease;
  transition: color .2s ease;
}

button:hover, button:focus {
  background-color: $bronze-color;
  color: $brown-color;
  border-color: $beige-color;
}

button.active {
  background-color: $bronze-color;
  font-weight: bold;
  color: $beige-color;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

input {
    outline: none;
    height: 2.4rem;
    font-size: .8rem;
    padding: .4rem;
    border-radius: 5px;
    border: 0.5px solid $brown-color;
}

a, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration: none;
}

.divider {
    height: 4px;
    background-color: $brown-color;
}

@font-face {
    font-family: "Berlin";
    src: url("./fonts/berlin.ttf") format('truetype');
    font-display: swap;
}
@font-face {
    font-family: "Berlin";
    src: url("./fonts/berlin-italic.ttf") format('truetype');
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: "Berlin";
    src: url("./fonts/berlin-bold.ttf") format('truetype');
    font-weight: bold;
    font-display: swap;
}
@font-face {
    font-family: "Berlin";
    src: url("./fonts/berlin-x-bold.ttf") format('truetype');
    font-weight: 900;
    font-display: swap;
}
